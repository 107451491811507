import auth0 from 'auth0-js';
import jsonwebtoken from 'jsonwebtoken';

export const loadToken = (email, password, callback) => {
  const webAuth = new auth0.WebAuth({
    domain: `${process.env.REACT_APP_DOMAIN_AUTH}`,
    clientID: `${process.env.REACT_APP_CLIENT_ID}`,
    audience: `${process.env.REACT_APP_AUDIENCE}`,
    responseType: 'id_token',
  });

  webAuth.client.login({
    realm: 'Username-Password-Authentication',
    username: email,
    password: password,
    scope: 'openid email profile',
  }, (err, resultAuth) => {
    if (err) {
      localStorage.setItem('accessToken', null);
      localStorage.setItem('idToken', null);
      callback(err);
    } else {
      localStorage.setItem('accessToken', resultAuth.accessToken);
      localStorage.setItem('idToken', resultAuth.idToken);
      callback(resultAuth);
    }
  });
} 

export const verifyToken = () => {
  const token = localStorage.getItem('idToken');
  const message = jsonwebtoken.decode(token);
  return token !== null && message !== null && message.exp <= Date.now();
};

export const removeToken = (callback) => {
  const webAuth = new auth0.WebAuth({
    domain: `${process.env.REACT_APP_DOMAIN_AUTH}`,
    clientID: `${process.env.REACT_APP_CLIENT_ID}`,
  });

  localStorage.removeItem('accessToken');
  localStorage.removeItem('idToken');

  webAuth.logout({ returnTo: process.env.REACT_APP_DOMAIN }, (err, resultAuth) => callback(resultAuth));
}
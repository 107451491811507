import React, { useState } from 'react';
import NotificationBannerPreview from './NotificationBannerPreview';
import Button from '@material-ui/core/Button';
import { Grid } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import EditIcon from '../../assets/images/icons/edit.svg';
import DeleteIcon from '../../assets/images/icons/delete.svg';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(3, 2),
    width: '25rem'
  },
  container: {
    marginBottom: '1rem',
    marginLeft: '5rem'
  },
  button: {
    borderRadius: '20px'
  },
  buttonEdit: {
    marginRight: '1rem'
  },
  icon: {
    width: '24px'
  },
  active: {
    backgroundColor: 'rgba(77, 222, 144, 0.08)'
  }
}));

const NotificationHistory = () => {
  const { t: translate } = useTranslation();
  const classes = useStyles();
  const [notificationList, setNotificationList] = useState({
    listType: 'card',
    notifications: [
      {
        hasVariable: false,
        image: '',
        title: 'Olá {username}',
        message: '{qty}',
        startDate: '25/05/2019',
        endDate: '02/06/2019',
        fromColor: '#10c0c6',
        toColor: '#4dde90',
        linkBtnAnchor: 'https://www.google.com',
        linkBtnText: 'Abrir Google'
      },
      {
        hasVariable: false,
        image: '',
        title: 'Olá {nickname}',
        message: '{totalValue}',
        startDate: '10/06/2019',
        endDate: '28/06/2019',
        fromColor: '#ed4581',
        toColor: '#fcbd1f',
        linkBtnAnchor: 'https://www.google.com',
        linkBtnText: 'Abrir Google'
      },
      {
        hasVariable: false,
        image: '',
        title: 'Olá {email}',
        message: '{broker}',
        startDate: '01/07/2019',
        endDate: '01/08/2019',
        fromColor: '#815ae8',
        toColor: '#0DD6F4',
        linkBtnAnchor: 'https://www.google.com',
        linkBtnText: 'Abrir Google'
      }
    ]
  });

  const handleChangelistType = (type) => {
    setNotificationList({ ...notificationList, listType: type });
  }

  const isTypeSelected = (type) => notificationList.listType === type;

  return (
    <>
      <Grid container justify="flex-start" alignItems="center" className={classes.container} spacing={2}>
        <Grid item>
          <Button color="secondary" className={isTypeSelected('card') ? classes.active : ''} onClick={() => handleChangelistType('card')}>
            {translate('card')}
          </Button>
        </Grid>
        <Grid item>
          <Button color="secondary" className={isTypeSelected('table') ? classes.active : ''} onClick={() => handleChangelistType('table')}>
            {translate('table')}
          </Button>
        </Grid>
      </Grid>
      <Grid container direction="row" justify="center" alignItems="center" spacing={2}>
        {notificationList.listType === 'card' ?
          notificationList.notifications.map((notification, index) => {
            return (
              <Grid item key={index}>
                <Paper className={classes.paper}>
                  <Typography variant="h6" color="textSecondary" gutterBottom>
                    {translate('active notification from @startDate to @endDate', {startDate: notification.startDate, endDate: notification.endDate})}
                  </Typography>
                  <Grid container direction="column" justify="center" alignItems="center" spacing={2}>
                    <Grid item>
                      <NotificationBannerPreview hasVariable={notification.hasVariable}
                        image={notification.image}
                        title={notification.title}
                        message={notification.message}
                        linkBtnText={notification.linkBtnText}
                        linkBtnAnchor={notification.linkBtnAnchor}
                        fromColor={notification.fromColor}
                        toColor={notification.toColor} />
                    </Grid>
                    <Grid item>
                      <Button variant="contained" color="primary" className={`${classes.button} ${classes.buttonEdit}`}>
                        {translate('edit')}
                      </Button>
                      <Button color="primary" className={classes.button}>
                        {translate('remove')}
                      </Button>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            )
          })
          : (
            <Paper>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>N</TableCell>
                    <TableCell>{translate('title')}</TableCell>
                    <TableCell>{translate('message')}</TableCell>
                    <TableCell>{translate('initial')}</TableCell>
                    <TableCell>{translate('final')}</TableCell>
                    <TableCell>{translate('active')}</TableCell>
                    <TableCell>{translate('actions')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {notificationList.notifications.map((notification, index) => (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        {index}
                      </TableCell>
                      <TableCell>{notification.title}</TableCell>
                      <TableCell>{notification.message}</TableCell>
                      <TableCell>{notification.startDate}</TableCell>
                      <TableCell>{notification.endDate}</TableCell>
                      <TableCell>{translate('yes')}</TableCell>
                      <TableCell>
                        <IconButton aria-label="Editar notificação">
                          <img src={EditIcon} alt="" className={classes.icon} />
                        </IconButton>
                        <IconButton aria-label="Deletar notificação">
                          <img src={DeleteIcon} alt="" className={classes.icon} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Paper>
          )}
      </Grid>
    </>
  )
}

export default NotificationHistory;
import React from 'react';
import { Route, Switch, Redirect, useHistory } from 'react-router-dom';
import NotificationActive from '../Notification/NotificationActive';
import NotificationCreate from '../Notification/NotificationCreate';
import NotificationHistory from '../Notification/NotificationHistory';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: '2rem'
  },
  active: {
    backgroundColor: 'rgba(16, 192, 198, 0.08)'
  }
}));

const Notification = props => {
  const { t: translate } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const previousRoute = props.match.url;
  const pathName = props.location.pathname;

  const handleChangePage = (page) => {
    history.push(`${previousRoute}/${page}`);
  }

  const isPageActive = (page) => pathName.search(page) !== -1;

  return (
    <>
      <Grid container justify="center" alignItems="baseline" spacing={2}>
        <Grid item>
          <Button color="primary" className={isPageActive('active') ? classes.active : ''} onClick={() => handleChangePage('active')}>{translate('active notifications')}</Button>
        </Grid>
        <Grid item>
          <Button color="primary" className={isPageActive('create') ? classes.active : ''} onClick={() => handleChangePage('create')}>{translate('create notification')}</Button>
        </Grid>
        <Grid item>
          <Button color="primary" className={isPageActive('history') ? classes.active : ''} onClick={() => handleChangePage('history')}>{translate('history')}</Button>
        </Grid>
      </Grid>
      <Grid container className={classes.container}>
        <Switch>
          <Route path={previousRoute + '/active'} component={NotificationActive} />
          <Route path={previousRoute + '/create'} component={NotificationCreate} />
          <Route path={previousRoute + '/history'} component={NotificationHistory} />
          <Redirect to={previousRoute + '/active'} />
        </Switch>
      </Grid>
    </>
  )
};

export default Notification;
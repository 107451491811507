import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { useTranslation } from 'react-i18next';

const ConfirmationModal = props => {
  const { t: translate } = useTranslation();
  return (
    <Dialog
      open={props.openModal}
      onClose={props.closeModal}
      aria-labelledby="Dialogo de logout"
      aria-describedby="Dialogo de logout"
    >
      <DialogContent>
        <DialogContentText>
          {translate('confirm log out')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.closeModal} variant="contained" data-testid="no" color="primary">
          {translate('no')}
        </Button>
        <Button onClick={props.logout} color="primary" data-testid="yes" autoFocus>
          {translate('yes')}
        </Button>
      </DialogActions>
    </Dialog>
  )
};

export default ConfirmationModal;
import React from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Route, Switch, Redirect, useHistory } from 'react-router-dom';
import UserDelete from './UserDelete';
import UserWallet from './UserWallet';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  active: {
    backgroundColor: 'rgba(16, 192, 198, 0.08)'
  }
}));

const User = props => {
  const { t: translate } = useTranslation();
  const history = useHistory();
  const previousRoute = props.match.url;
  const pathName = props.location.pathname;
  const classes = useStyles();

  const handleChangePage = (page) => {
    history.push(`${previousRoute}/${page}`);
  }

  const isPageActive = (page) => pathName.search(page) !== -1;

  return (
    <>
      <Grid container justify="center" alignItems="baseline" spacing={2}>
        <Grid item>
          <Button color="primary" className={isPageActive('delete') ? classes.active : ''} onClick={() => handleChangePage('delete')}>
            {translate('delete user')}
          </Button>
        </Grid>
        <Grid item>
          <Button color="primary" className={isPageActive('wallet') ? classes.active : ''} onClick={() => handleChangePage('wallet')}>
            {translate('update wallet')}
          </Button>
        </Grid>
      </Grid>
      <Grid>
        <Switch>
          <Route path={previousRoute + '/delete'} component={UserDelete} />
          <Route path={previousRoute + '/wallet'} component={UserWallet} />
          <Redirect to={previousRoute + '/delete'} />
        </Switch>
      </Grid>
    </>
  )
};

export default User;
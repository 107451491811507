import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTranslation } from 'react-i18next';

const SecurityNameListModal = props => {
  const { t: translate } = useTranslation();
  return (
    <Dialog open={props.openModal} onClose={props.closeModal}>
    <DialogTitle>{translate('list of products')}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Produto 1...
        </DialogContentText>
      </DialogContent>
    </Dialog>
  )
};

export default SecurityNameListModal;
import React, { useState } from 'react';
import loadDictionary from '../../shared/functions/dictionary';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';

const DictionaryModal = props => {
  const { t: translate } = useTranslation();
  const [ clicked, setClicked ] = useState(false);
  let variables = loadDictionary(props.type);
  let modalBody = [];
  variables = Object.entries(variables);
  
  const handleCloseModal = () => {
    props.closeModal();
    setClicked(false);
  }

  for (let i = 0; i < variables.length; i++) {
    modalBody.push(
      <Grid container justify="space-between" key={variables[i][0]}>
        <Grid item>
          <CopyToClipboard text={'{' + variables[i][0] + '}'}>
            <p onClick={() => setClicked(true)}>&#123;{variables[i][0]}&#125;</p>
          </CopyToClipboard>
        </Grid>
        <Grid item>
          <p>{variables[i][1]}</p>
        </Grid>
      </Grid>
    )
  }

  return (
    <Dialog 
      open={props.openModal} 
      onClose={handleCloseModal}       
      fullWidth
    >
      <DialogTitle>{translate('variable dictionary')}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {clicked ? <Typography align="center" variant="h6" color="textSecondary" gutterBottom>{translate('copied')}!</Typography> : null}
          {modalBody}
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default DictionaryModal;
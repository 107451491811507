import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';

const UserDelete = () => {
  const { t: translate } = useTranslation();

  return (
    <Grid container justify="center" alignItems="center">
      <Typography variant="h5" color="textSecondary">{translate('delete user')}</Typography>
    </Grid>
  )
};

export default UserDelete;
import React, { useEffect } from 'react';
import { Switch } from 'react-router-dom';
import { Header } from './shared';
import AuthenticatedApp from './routes/AuthenticatedApp';
import UnauthenticatedApp from './routes/UnautheticatedApp';
import { useAuthState, useAuthDispatch, checkToken } from './context/authentication';
require('dotenv-extended').load();

const App = () => {
  const state = useAuthState();
  const dispatch = useAuthDispatch();

  useEffect(() => {
    checkToken(dispatch);
  }, [state.isAuthenticate, dispatch]);

  return (
    <>
      <Header />
      <Switch>
        { state.isAuthenticate ?  <AuthenticatedApp /> : <UnauthenticatedApp /> }
      </Switch>
    </>
  );
};

export default App;

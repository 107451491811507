import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { Notification, User } from '../components';

const AuthenticatedApp = () => (
    <>
      <Route path="/notification" component={Notification} />
      <Route path="/user" component={User} />
      <Redirect exact from="/" to="/notification" />
    </>
);

export default AuthenticatedApp;
const loadDictionary = type => {
    let variables = {
        username: 'Nome do usuário',
        email: 'E-mail do usuário',
        nickname: 'Apelido do usuário',
        type: 'Tipo de evento',
        totalValue: 'Valor total',
        qty: 'Quantidade',
        value: 'Valor',
        securityName: 'Nome da security',
        broker: 'Broker',
        eventDate: 'Dia do evento'
    };

    switch (type) {
        case 'communication':
            variables = {
                username: 'Nome do usuário',
                email: 'E-mail do usuário',
                nickname: 'Apelido do usuário'
            };
            break;
        case 'corporate actions':
            variables = {
                username: 'Nome do usuário',
                email: 'E-mail do usuário',
                nickname: 'Apelido do usuário',
                type: 'Tipo de evento',
                totalValue: 'Valor total',
                qty: 'Quantidade',
                value: 'Valor',
                securityName: 'Nome da security',
                broker: 'Broker',
                eventDate: 'Dia do evento'
            };
            break;
        case 'user status':
            variables = {
                username: 'Nome do usuário',
                email: 'E-mail do usuário',
                nickname: 'Apelido do usuário',
                lastLoginDate: 'Último dia de login'
            };
            break;
        default:
            return variables;
    }

    return variables;
};

export default loadDictionary;
export const GorilaDefault = '#10c0c6';
export const GorilaSecondary = '#4dde90';
export const RendaFixaDefault = '#815ae8';
export const RendaFixaSecondary = '#9278f4';
export const MultimercadoDefault = '#0DD6F4';
export const MultimercadoSecondary = '#43E7FF';
export const RendaVariavelDefault = '#ed4581';
export const RendaVariavelSecondary = '#f766a1';
export const MoedasDefault = '#fcbd1f';
export const MoedasSecondary = '#ffd364';
export const textDefault = '#333';
export const textSecondary = '#666';
export const Negative = '#ef2e2e';
export const White = '#fff';
export const Black = '#000';

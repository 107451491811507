import React, { Component } from 'react';
import NotificationBannerPreview from './NotificationBannerPreview';
import { DictionaryModal, SecurityNameListModal, color } from '../../shared';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { CompactPicker } from 'react-color';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { withStyles, createStyles } from '@material-ui/core';
import { withTranslation } from 'react-i18next';

const styles = (theme) => createStyles({
  paper: {
    padding: '1.5rem'
  },
  active: {
    backgroundColor: 'rgba(77, 222, 144, 0.08)'
  },
  formControl: {
    minWidth: 250,
  },
  button: {
    borderRadius: '20px',
  },
  buttonClean: {
    borderRadius: '20px',
  },
  input: {
    borderBottom: '1px solid rgb(51, 51, 51)',
    minWidth: 250,
    font: 'inherit',
    border: 0,
    padding: '6px 0 7px',
    outline: 0,
  },
  positionFixed: {
    position: 'fixed',
    [theme.breakpoints.between('xs', 'sm')]: {
      position: 'unset',
    }
  },
  buttonConatainer: {
    marginBottom: '1rem'
  }
});

class NotificationCreate extends Component {

  state = {
    controls: {
      channel: {
        value: 'banner',
        valid: true,
        touched: true
      },
      type: {
        value: 'communication',
        valid: true,
        touched: true
      },
      audience: {
        value: 'all',
        valid: true,
        touched: true,
        selectedAssetClass: {
          stocks: false,
          crypto: false,
          funds: false,
          multimarket: false,
          fixedIncome: false,
          options: false,
          coe: false,
          cricra: false
        }
      },
      emailList: {
        value: '',
        valid: false,
        touched: false,
        validation: {
          required: true
        }
      },
      title: {
        value: '',
        valid: false,
        touched: false,
        validation: {
          required: true,
          minLength: 3,
          maxLength: 25
        }
      },
      message: {
        value: '',
        valid: false,
        touched: false,
        validation: {
          required: true,
          minLength: 10,
          maxLength: 150
        }
      },
      linkBtnText: {
        value: '',
        valid: true,
        touched: true,
        validation: {
          required: false
        }
      },
      linkBtnAnchor: {
        value: '',
        valid: true,
        touched: false,
        validation: {
          required: false
        }
      },
      startDate: {
        value: new Date(),
        touched: false
      },
      endDate: {
        value: new Date(),
        touched: false
      },
      fromColor: {
        value: color.GorilaDefault
      },
      toColor: {
        value: color.GorilaSecondary
      }
    },
    image: '',
    hasNotificationPeriod: false,
    hasVariable: true,
    dictionaryModalVisible: false,
    securityNameListModalVisible: false
  };

  checkValidityRules(value, rules) {
    const trimmedValue = value.trim();
    if (rules.required && !trimmedValue) {
      return false;
    }
    if (rules.minLength && trimmedValue.length <= rules.minLength) {
      return false;
    }
    if (rules.maxLength && trimmedValue.length >= rules.maxLength) {
      return false;
    }

    return true;
  }

  checkFormValidity() {
    if (this.state.controls.audience.value === 'email' && !this.state.controls.emailList.valid) {
      return false;
    }
    if (this.state.controls.linkBtnText.value && this.state.controls.linkBtnAnchor.value) {
      return false;
    }

    return this.state.controls.title.valid && this.state.controls.message.valid &&
      this.state.controls.endDate.value.getTime() >= this.state.controls.startDate.value.getTime();
  }

  imageChangedHandler = event => {
    if (event.target.files && event.target.files[0] && event.target.files[0] instanceof File) {
      this.setState({
        image: URL.createObjectURL(event.target.files[0])
      });
    }
  };

  inputChangedHandler = (value, key) => {
    this.setState(prevState => {
      return {
        ...prevState,
        controls: {
          ...prevState.controls,
          [key]: {
            ...prevState.controls[key],
            value: value,
            valid: prevState.controls[key].validation ? this.checkValidityRules(value, prevState.controls[key].validation) : true,
            touched: true
          }
        }
      }
    });
  };

  dateChangedHandler = (value, key) => {
    this.setState(prevState => {
      return {
        ...prevState,
        controls: {
          ...prevState.controls,
          [key]: {
            ...prevState.controls[key],
            value: value,
            touched: true
          }
        }
      }
    });
  };

  colorChangedHandler = (value, key) => {
    this.setState(prevState => {
      return {
        ...prevState,
        controls: {
          ...prevState.controls,
          [key]: {
            ...prevState.controls[key],
            value: value.hex
          }
        }
      }
    });
  };

  checkBoxChangedHandler = (value, key) => {
    this.setState(prevState => {
      return {
        ...prevState,
        controls: {
          ...prevState.controls,
          audience: {
            ...prevState.controls.audience,
            selectedAssetClass: {
              ...prevState.controls.audience.selectedAssetClass,
              [key]: value
            }
          }
        }
      }
    });
  };

  clearForm = () => {
    this.setState(prevState => {
      return {
        ...prevState,
        controls: {
          ...prevState.controls,
          emailList: {
            ...prevState.controls.emailList,
            value: ''
          },
          title: {
            ...prevState.controls.title,
            value: '',
            valid: false,
            touched: false
          },
          message: {
            ...prevState.controls.message,
            value: '',
            valid: false,
            touched: false
          },
          linkBtnText: {
            ...prevState.controls.linkBtnText,
            value: ''
          },
          linkBtnAnchor: {
            ...prevState.controls.linkBtnAnchor,
            value: ''
          },
          image: '',
          hasNotificationPeriod: false,
          hasVariable: true
        }
      }
    });
  };

  openModal = (type) => {
    switch (type) {
      case 'dictionary':
        this.setState({ dictionaryModalVisible: true, securityNameListModalVisible: false });
        break;
      case 'securityName':
        this.setState({ dictionaryModalVisible: false, securityNameListModalVisible: true });
        break;
      default:
        return;
    }
  };

  closeModal = () => {
    this.setState({
      dictionaryModalVisible: false,
      securityNameListModalVisible: false
    });
  };

  hasVariableHandler = variable => {
    this.setState({ hasVariable: variable });
  };

  render() {
    const { classes, t: translate } = this.props;

    const emailList = (
      <TextField
        className={classes.formControl}
        error={!this.state.controls.emailList.valid && this.state.controls.emailList.touched}
        onChange={event => this.inputChangedHandler(event.target.value, 'emailList')}
        id="emailList"
        label={translate('emails')}
        helperText={!this.state.controls.emailList.valid && this.state.controls.emailList.touched ? translate('invalid list') : ''}
        value={this.state.controls.emailList.value}
        margin="normal"
      />
    );

    const checkBoxes = ['stocks', 'crypto', 'funds', 'multimarket', 'fixedIncome', 'options', 'coe', 'cricra'];

    const checkBoxList = [];
    checkBoxes.map((checkBox, index) => {
      checkBoxList.push(
        <Grid item key={index}>
          <FormControlLabel
            control={
              <Checkbox
                id={checkBox}
                checked={this.state.controls.audience.selectedAssetClass[checkBox]}
                onChange={event => this.checkBoxChangedHandler(event.target.checked, checkBox)}
                color="primary"
              />
            }
            label={translate(checkBox)}
          />
        </Grid>
      );
      return `<Grid container> ${checkBoxList} </Grid>`;
    });

    const openSecurityNameModal = (
      <Grid container justify="center" alignItems="center">
        <Button variant="contained" color="primary" className={classes.button} onClick={() => this.openModal('securityName')}>
          {translate('list of products')}
        </Button>
      </Grid>
    );

    const brokerIssuerList = (
      <>
        <Typography variant="h6">{translate('list of broker issuer')}</Typography>
        <Typography variant="body1">
          Corretora 1...
        </Typography>
      </>
    );

    const dateForm = [];
    let index = 0;

    if (this.state.controls.channel.value === 'banner') {
      dateForm.push(
        <Grid item key={index++}>
          <FormControlLabel
            control={
              <Checkbox
                id="hasNotificationPeriod"
                checked={this.state.hasNotificationPeriod}
                onChange={() => this.setState(prevState => ({
                    hasNotificationPeriod: !prevState.hasNotificationPeriod
                })
                )}
                color="primary"
              />
            }
            label={translate('select period')}
          />
        </Grid>
      );
      if (this.state.hasNotificationPeriod) {
        dateForm.push(
          <Grid item key={index++}>
            <Grid container spacing={2}>
              <Grid item>
                <DatePicker className={classes.input}
                  selectsStart
                  selected={this.state.controls.startDate.value}
                  startDate={this.state.controls.startDate.value}
                  endDate={this.state.controls.endDate.value}
                  onChange={value => this.dateChangedHandler(value, 'startDate')}
                  minDate={new Date()}
                  dateFormat="dd MMMM, yyyy"
                  dropdownMode="select"
                />
              </Grid>
              <Grid item>
                <DatePicker className={classes.input}
                  selectsEnd
                  selected={this.state.controls.endDate.value}
                  startDate={this.state.controls.startDate.value}
                  endDate={this.state.controls.endDate.value}
                  onChange={value => this.dateChangedHandler(value, 'endDate')}
                  minDate={this.state.controls.startDate.value}
                  dateFormat="dd MMMM, yyyy"
                  dropdownMode="select"
                />
              </Grid>
            </Grid>
            {this.state.controls.endDate.value.getTime() < this.state.controls.startDate.value.getTime()
              ? <small className="form-text text-danger text-right">
                {translate('invalid final date')}
                </small>
              : null}
          </Grid>
        );
      } else {
        dateForm.push(
          <Grid item key={index++}>
            <DatePicker className={classes.input}
              selectsStart
              selected={this.state.controls.startDate.value}
              startDate={this.state.controls.startDate.value}
              endDate={this.state.controls.endDate.value}
              onChange={value => this.dateChangedHandler(value, 'startDate')}
              minDate={new Date()}
              dateFormat="dd MMMM, yyyy"
              dropdownMode="select"
            />
          </Grid>
        );
      }
    }

    return (
      <Grid container direction="row" justify="center" alignItems="flex-start" spacing={4}>
        <Grid item lg={4} md={6} sm={12} xs={12}>
          <Paper className={classes.paper}>
            <Typography variant="h5" color="textSecondary" gutterBottom>
              {translate('create notification')}
            </Typography>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <FormControl className={classes.formControl}>
                  <InputLabel id="channel">{translate('communication channel')}</InputLabel>
                  <Select
                    labelId="channel"
                    value={this.state.controls.channel.value}
                    onChange={event => this.inputChangedHandler(event.target.value, 'channel')}
                  >
                    <MenuItem value="banner">{translate('banner')}</MenuItem>
                    <MenuItem value="email">{translate('email')}</MenuItem>
                    <MenuItem value="app">{translate('app')}</MenuItem>
                    <MenuItem value="modal">{translate('modal')}</MenuItem>
                    <MenuItem value="sms">{translate('sms')}</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl className={classes.formControl}>
                  <InputLabel id="type">{translate('type')}</InputLabel>
                  <Select
                    labelId="type"
                    value={this.state.controls.type.value}
                    onChange={event => this.inputChangedHandler(event.target.value, 'type')}
                  >
                    <MenuItem value="communication">{translate('communication')}</MenuItem>
                    <MenuItem value="corporate actions">{translate('corporate actions')}</MenuItem>
                    <MenuItem value="user status">{translate('user status')}</MenuItem>
                    <MenuItem value="other">{translate('other')}</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item>
                <Grid container justify="center" alignItems="center">
                  <Button variant="contained" color="primary" className={classes.button} onClick={() => this.openModal('dictionary')}>
                    {translate('list of variables')}
                  </Button>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container justify="center" alignItems="center" spacing={2}>
                  <Grid item>
                    <Typography variant="h6">
                      {translate('example title with variable')}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <span>&#8680;</span>
                  </Grid>
                  <Grid item>
                    <Typography variant="h6">
                      {translate('example title without variable')}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <FormControl className={classes.formControl}>
                  <InputLabel id="audience">{translate('audience')}</InputLabel>
                  <Select
                    labelId="audience"
                    value={this.state.controls.audience.value}
                    onChange={event => this.inputChangedHandler(event.target.value, 'audience')}
                  >
                    <MenuItem value="all">{translate('all')}</MenuItem>
                    <MenuItem value="asset class">{translate('asset class')}</MenuItem>
                    <MenuItem value="security name">{translate('security name')}</MenuItem>
                    <MenuItem value="broker issuer">{translate('broker issuer')}</MenuItem>
                    <MenuItem value="empty wallet">{translate('empty wallet')}</MenuItem>
                    {this.state.controls.channel.value === 'email' ?
                      <MenuItem value="not logged">{translate('last login')}</MenuItem> : null}
                    <MenuItem value="email">{translate('email')}</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item>
                {this.state.controls.audience.value === 'email'
                  ? emailList
                  : this.state.controls.audience.value === 'asset class'
                    ? checkBoxList
                    : this.state.controls.audience.value === 'security name'
                      ? openSecurityNameModal
                      : this.state.controls.audience.value === 'broker issuer'
                        ? brokerIssuerList
                        : null}
              </Grid>
              <Grid item>
                <Typography variant="body2" color="textSecondary">{translate('image')}</Typography>
                <input type="file" className="form-control-file" id="image"
                  value={this.state.image}
                  onChange={event => this.imageChangedHandler(event)} />
              </Grid>
              <Grid item>
                <TextField
                  className={classes.formControl}
                  error={!this.state.controls.title.valid && this.state.controls.title.touched}
                  onChange={event => this.inputChangedHandler(event.target.value, 'title')}
                  id="title"
                  label={translate('title')}
                  helperText={!this.state.controls.title.valid && this.state.controls.title.touched ? translate('invalid title') : ''}
                  value={this.state.controls.title.value}
                  margin="normal"
                />
              </Grid>
              <Grid item>
                <TextField
                  className={classes.formControl}
                  error={!this.state.controls.message.valid && this.state.controls.message.touched}
                  onChange={event => this.inputChangedHandler(event.target.value, 'message')}
                  id="message"
                  rows="5"
                  label={translate('message')}
                  multiline
                  helperText={!this.state.controls.message.valid && this.state.controls.message.touched ? translate('invalid message') : ''}
                  value={this.state.controls.message.value}
                  margin="normal"
                />
              </Grid>
              <Grid item>
                <TextField
                  className={classes.formControl}
                  onChange={event => this.inputChangedHandler(event.target.value, 'linkBtnText')}
                  id="linkBtnText"
                  label={translate('(optional) button')}
                  value={this.state.controls.linkBtnText.value}
                  margin="normal"
                />
              </Grid>
              <Grid item>
                <TextField
                  className={classes.formControl}
                  error={this.state.controls.linkBtnText.value && this.state.controls.linkBtnAnchor.touched && this.state.controls.linkBtnAnchor.value ? true : false}
                  onChange={event => this.inputChangedHandler(event.target.value, 'linkBtnAnchor')}
                  id="linkBtnAnchor"
                  label={`${translate('link')} ${this.state.controls.linkBtnText.value ? '' : translate('(optional)')}`}
                  helperText={this.state.controls.linkBtnText.value && this.state.controls.linkBtnAnchor.touched && this.state.controls.linkBtnAnchor.value ? translate('invalid link') : ''}
                  value={this.state.controls.linkBtnAnchor.value}
                  margin="normal"
                />
              </Grid>
              {dateForm}
              <Grid item>
                <Typography variant="h6" color="textSecondary">
                  {translate('banner gradient colors')}
                </Typography>
                <Grid container direction="column" justify="center" alignItems="flex-start" spacing={2}>
                  <Grid item>
                    <Typography variant="body1" color="textSecondary">
                      E
                    </Typography>
                    <CompactPicker colors={Object.values(color)}
                      color={this.state.controls.fromColor.value}
                      onChangeComplete={value => this.colorChangedHandler(value, 'fromColor')}
                    />
                  </Grid>
                  <Grid item>
                    <Typography variant="body1" color="textSecondary">
                      D
                    </Typography>
                    <CompactPicker colors={Object.values(color)}
                      color={this.state.controls.toColor.value}
                      onChangeComplete={value => this.colorChangedHandler(value, 'toColor')}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container direction="row" justify="flex-end" spacing={2}>
                  <Grid item>
                    <Button variant="contained" color="primary" className={classes.button} disabled={!this.checkFormValidity()}
                      onClick={this.createNotification}>
                      {translate('create')}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button color="primary" className={classes.buttonClean} onClick={this.clearForm}>
                      {translate('clean')}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item lg={3} md={6} sm={12} xs={12}>
          <div className={classes.positionFixed}>
            <Paper className={classes.paper}>
              <Typography variant="h5" color="textSecondary" gutterBottom>
                {translate('preview')}
              </Typography>
              <Grid container direction="row" justify="center" alignItems="center" className={classes.buttonConatainer}>
                <Button color="secondary" className={this.state.hasVariable ? classes.active : ''} onClick={() => this.hasVariableHandler(true)}>
                  {translate('with variable')}
                </Button>
                <Button color="secondary" className={!this.state.hasVariable ? classes.active : ''} onClick={() => this.hasVariableHandler(false)}>
                  {translate('without variable')}
                </Button>
              </Grid>
              <Grid container direction="row" justify="center" alignItems="center">
                <NotificationBannerPreview hasVariable={this.state.hasVariable}
                  image={this.state.image}
                  type={this.state.controls.type.value}
                  title={this.state.controls.title.value}
                  message={this.state.controls.message.value}
                  linkBtnText={this.state.controls.linkBtnText.value}
                  linkBtnAnchor={this.state.controls.linkBtnAnchor.value}
                  fromColor={this.state.controls.fromColor.value}
                  toColor={this.state.controls.toColor.value} />
              </Grid>
            </Paper>
          </div>
        </Grid>
        <DictionaryModal openModal={this.state.dictionaryModalVisible} type={this.state.controls.type.value} closeModal={this.closeModal} />
        <SecurityNameListModal openModal={this.state.securityNameListModalVisible} closeModal={this.closeModal} />
      </Grid>
    )
  }
}

export default withTranslation()(withStyles(styles)(NotificationCreate));
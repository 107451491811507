import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import ConfirmationModal from '../modals/ConfirmationModal';
import { useAuthState, useAuthDispatch, revokeToken } from '../../context/authentication';
import Logo from '../../assets/images/logo/logo_gorila.svg';
import MenuIcon from '../../assets/images/icons/hamb-menu.svg';
import LeftArrowIcon from '../../assets/images/icons/chevron_left.svg';
import RightArrowIcon from '../../assets/images/icons/chevron_right.svg';
import Clients from '../../assets/images/icons/clients.svg';
import NotificationIcon from '../../assets/images/icons/notification.svg';
import LogoutIcon from '../../assets/images/icons/logout.svg';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  header: {
    marginBottom: '5rem'
  },
  appBar: {
    backgroundColor: '#fff',
  },
  logo: {
    width: '10rem'
  },
  drawerPaper: {
    width: 240,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  }
}));

const Header = () => {
  const state = useAuthState();
  const dispatch = useAuthDispatch();
  const { t: translate } = useTranslation();
  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [modalVisible, setModalVisible] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const hanldeCloseModal = () => {
    setModalVisible(false);
  };

  const logout = () => {
    revokeToken(dispatch);
    setOpen(false);
    setModalVisible(false);
  };

  return (
    <div className={classes.header}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="abrir menu"
            onClick={handleDrawerOpen}
            edge="start"
            className={`${classes.menuButton} ${open && classes.hide}`}
          >
          { state.isAuthenticate && <img alt="" src={MenuIcon} /> }
          </IconButton>
          <img src={Logo} className={classes.logo} alt="logo" />
        </Toolbar>
      </AppBar>
      { 
        state.isAuthenticate 
        &&
        <Drawer
          variant="persistent"
          classes={{
            paper: classes.drawerPaper,
          }}
          anchor="left"
          open={open}
        >
          <div className={classes.drawerHeader}>
            <IconButton onClick={handleDrawerClose}>
              <img alt="" src={theme.direction === 'ltr' ? LeftArrowIcon : RightArrowIcon } />
            </IconButton>
          </div>
          <Divider />
          <List>
            <ListItem button onClick={() => history.push('/notification')} data-testid="goToNotificationPageButton">
              <ListItemIcon><img alt="" src={NotificationIcon} /></ListItemIcon>
              <ListItemText primary={translate('notifications')} />
            </ListItem>
            <ListItem button onClick={() => history.push('/user')} data-testid="goToUsersPageButton">
              <ListItemIcon><img alt="" src={Clients} /></ListItemIcon>
              <ListItemText primary={translate('users')}  />
            </ListItem>
            <ListItem button onClick={() => setModalVisible(true)} data-testid="logoutButton">
              <ListItemIcon><img alt="" src={LogoutIcon} /></ListItemIcon>
              <ListItemText primary={translate('log out')}  />
            </ListItem>
          </List>
        </Drawer>
      }
      <ConfirmationModal openModal={modalVisible} closeModal={hanldeCloseModal} logout={logout} />
    </div>
  )
};

export default Header;



import React from 'react';
import { loadToken, verifyToken, removeToken } from '../services/auth';
const AuthStateContext = React.createContext();
const AuthDispatchContext = React.createContext();

const initialState = {
    errorMessage: null,
    loadingLogin: false,
    isAuthenticate: false,
};

function authReducer(state = initialState, action) {
  switch (action.type) {
    case 'loading': {
      return { ...state, loadingLogin: true, errorMessage: null }
    }
    case 'set_authentication_state': {
      return { ...state, isAuthenticate: action.token }
    }
    case 'update-error-message': {
      return { ...state, errorMessage: action.message, loadingLogin: false }
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

function AuthProvider({children}) {
  const [state, dispatch] = React.useReducer(authReducer, {});
  return (
    <AuthStateContext.Provider value={state}>
      <AuthDispatchContext.Provider value={dispatch}>
        {children}
      </AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  )
}

function getToken(dispatch, { email, password }) {
  dispatch({ type: 'loading' });
  loadToken(email, password, (err) => {
    if (err && err.description) {
      dispatch({ type: 'set_authentication_state', token: false });
      dispatch({ type: 'update-error-message', message: err.description });
    }else {
      dispatch({ type: 'set_authentication_state', token: true });
      dispatch({ type: 'update-error-message', message: null });
    }
  });
}

function revokeToken(dispatch) {
  removeToken(() => {
    dispatch({ type: 'set_authentication_state', token: false });
  });
}

function checkToken(dispatch) {
  const isTokenValid = verifyToken();
  dispatch({ type: 'set_authentication_state', token: isTokenValid });
}

function useAuthDispatch() {
  const context = React.useContext(AuthDispatchContext);
  if (context === undefined) {
    throw new Error(`useAuthDispatch must be used within a AuthenticationProvider`);
  }
  return context;
}

function useAuthState() {
  const context = React.useContext(AuthStateContext);
  if (context === undefined) {
    throw new Error(`useAuthState must be used within a AuthenticationProvider`);
  }
  return context;
}

export {
  AuthProvider,
  useAuthDispatch,
  useAuthState,
  getToken,
  revokeToken,
  checkToken
}